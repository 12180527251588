import { RouteRecordRaw } from 'vue-router';

const UserRoutes: RouteRecordRaw[] = [
  {
    path: '/h5/expert',
    component: () => import('@/components/expert-h5/AppView.vue'),
    meta: { auth: [''] },
    children: [
      { path: '', redirect: '/h5/expert/home' },
      { path: 'login', component: () => import('@/components/expert-h5/LoginView.vue') },
      {
        path: 'home',
        component: () => import('@/components/expert-h5/HomeView.vue'),
      },
      {
        path: 'me',
        component: () => import('@/components/expert-h5/MeView.vue'),
      },
      {
        path: 'password',
        component: () => import('@/components/expert-h5/ResetPasswordView.vue'),
      },
      {
        path: 'commitments/:activityId',
        component: () => import('@/components/expert-h5/CommitmentsView.vue'),
      },
      {
        path: 'review/list/:activityId',
        component: () => import('@/components/expert-h5/ReviewListView.vue'),
      },
      {
        path: 'search',
        component: () => import('@/components/expert-h5/SearchView.vue')
      }, {
        path: 'project/detail/:id',
        component: () => import('@/components/expert-h5/ProjectDetaiView.vue')
      },{ 
        path: 'review/note',
        component: () => import('@/components/expert-h5/ReviewNoteView.vue')
      },
      {
        path: 'sign/name/:activityId',
        component: () => import('@/components/expert-h5/SignName.vue'),
      },
    ],
  },
];

export default UserRoutes;


import { RouteRecordRaw } from 'vue-router';

const ProjMgmtRoutes: RouteRecordRaw[] = [
  {
    path: '/proj-review',
    component: () => import('@/components/proj-review/ProjReviewView.vue'),
    meta: { auth: ['ROLE_EXPERT'], group: 'proj-review', menuGroup: 'proj-review' },
    children: [
      { path: '', redirect: '/proj-review/nav' },
      {
        path: 'nav',
        component: () => import('@/components/proj-review/ProjReviewNavView.vue'),
        meta: { breadcrumb: [{ label: '项目评审' }] },
      },
      {
        path: 'detail/:id',
        name: 'ProjReviewDetailView',
        component: () => import('@/components/proj-review/ProjReviewDetailView.vue'),
        meta: { breadcrumb: [{ label: '项目评审', path: '/proj-review/nav' }, { label: '项目评审列表', path: '/proj-review/list/1/1' }, { label: '项目评审详情' },] },

      },
      {
        path: 'list/:activityId/:page',
        component: () => import('@/components/proj-review/ProjReviewListView.vue'),
        meta: { breadcrumb: [{ label: '项目评审列表' }], isBack: false },
      }
    ],
  },
];

export default ProjMgmtRoutes;

import { RouteRecordRaw } from 'vue-router';

const ProjAplsRoutes: RouteRecordRaw[] = [
  {
    path: '/proj-apls',
    component: () => import('@/components/proj-apls/AplsView.vue'),
    meta: { auth: ['ROLE_USER'], group: 'proj-apls', menuGroup: 'proj-apls' },
    children: [
      { path: '', redirect: '/proj-apls/list' },
      {
        path: 'center/:page',
        component: () => import('@/components/proj-apls/CenterView.vue'),
        meta: { breadcrumb: [{ label: '申报中心' }] },
      },
      {
        path: 'list/:page',
        component: () => import('@/components/proj-apls/ListView.vue'),
        meta: { breadcrumb: [{ label: '我的申报' }] },
      },
      {
        path: 'draft/:page',
        component: () => import('@/components/proj-apls/DraftsView.vue'),
        meta: { breadcrumb: [{ label: '草稿箱' }] }
      },
      {
        path: 'form/:activityID',
        component: () => import('@/components/proj-apls/FormView.vue'),
        meta: {
          breadcrumb: [
            { label: '申报中心', path: '/proj-apls/center/1' },
            { label: '申请信息录入' },
          ],
        },
      },
      {
        path: 'editor',
        component: () => import('@/components/proj-apls/EditorView.vue'),
        meta: {
          breadcrumb: [
            { label: '我的申报', path: '/proj-apls/list/1' },
            { label: '申请信息编辑' },
          ],
        },
      },
      {
        path: 'draft/editor/:id',
        component: () => import('@/components/proj-apls/DraftEditorView.vue'),
        meta: {
          breadcrumb: [
            { label: '草稿箱', path: '/proj-apls/draft/1' },
            { label: '草稿箱信息编辑' },
          ],
        }
      },
      {
        path: 'text/:id',
        component: () => import('@/components/proj-apls/DetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '我的申报', path: '/proj-apls/list/1' },
            { label: '申请信息查看' },
          ],
        },
      },
      {
        path: 'tech-prod-form/:activityID',
        component: () => import('@/components/proj-apls/tech-prod/FormView.vue'),
        meta: {
          breadcrumb: [
            { label: '申报中心', path: '/proj-apls/center/1' },
            { label: '申请信息录入' },
          ],
        },
      },
      {
        path: 'tech-prod-detail/:id',
        component: () => import('@/components/proj-apls/tech-prod/DetailView.vue'),
        meta: {
          breadcrumb: [
            { label: '我的申报', path: '/proj-apls/list/1' },
            { label: '申请信息查看' },
          ],
        },
      },
      {
        path: 'tech-prod-editor',
        component: () => import('@/components/proj-apls/tech-prod/EditorView.vue'),
        meta: {
          breadcrumb: [
            { label: '我的申报', path: '/proj-apls/list/1' },
            { label: '申请信息编辑' },
          ],
        },
      },
    ],
  },
];

export default ProjAplsRoutes;

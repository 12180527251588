import { RouteRecordRaw } from 'vue-router';

const AuthRoutes: RouteRecordRaw[] = [
  {
    path: '/auth',
    component: () => import('@/components/auth/AuthView.vue'),
    meta: { auth: [''] },
    children: [
      { path: '', redirect: '/auth/login' },
      { path: 'login', component: () => import('@/components/auth/LoginView.vue') },
      { path: 'register', component: () => import('@/components/auth/RegisterView.vue') },
      { path: 'forget', component: () => import('@/components/auth/ForgetPasswordView.vue') },
    ],
  },
];

export default AuthRoutes;

import { createStore } from 'vuex';

const store = createStore({
  state: {
    orgTypeArr: null,
    prodTypeArr: null,
    addressData: [],
    provinceArr: [],
    cityDic: {},
    areaDic: {},
    aplsData: null,
    adminActivityArr: [] as any[],
  },
  mutations: {
    setAddressData(state, data) {
      state.addressData = data;
      state.provinceArr = data.map(({ id, name }: any) => ({ id, name }));
      state.cityDic = data.reduce(
        (acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur.cities }),
        {},
      );
      state.areaDic = data
        .reduce((acc: any, cur: any) => [...acc, ...cur.cities], [])
        .reduce(
          (acc: any, cur: any) =>
            Object.assign(acc, { [cur.id]: cur.countyVos }),
          {},
        );
    },
    setAplsData(state, data) {
      console.log("state",data)
      state.aplsData = data;
    },
    setOrgTypeArr(state, arr) {
      state.orgTypeArr = arr;
    },
    setProdTypeArr(state, arr) {
      state.prodTypeArr = arr;
    },
    setAdminActivityArr(state, arr) {
      state.adminActivityArr = arr;
    },
  },
});

export default store;

import { RouteRecordRaw } from 'vue-router';

const UserRoutes: RouteRecordRaw[] = [
  {
    path: '/user',
    component: () => import('@/components/user/UserView.vue'),
    meta: { auth: [''], group: 'user', menuGroup: 'user' },
    children: [
      { path: '', redirect: '/user/info' },
      {
        path: 'info',
        component: () => import('@/components/user/InfoView.vue'),
        meta: { breadcrumb: [{ label: '基本信息' }] },
      },
      {
        path: 'pwd',
        component: () => import('@/components/user/PwdView.vue'),
        meta: { breadcrumb: [{ label: '密码修改' }] },
      },
    ],
  },
];

export default UserRoutes;

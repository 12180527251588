import axios from 'axios';
import { BASE_URL } from './ENV';
import { getToken } from '@/utils';

const baseURL = BASE_URL as string;

const http = axios.create({
  baseURL,
});

function login(username: string, password: string) {
  return http.post('/authorize/token', { username, password });
}

function getProfile() {
  return http.get('/user/me', {
    headers: {
      Authorization: getToken(),
    },
  });
}

export { login, getProfile };

<template>
  <div class="body flex-col">
    <div class="flex-row">
      <img class="img" :src="item.url" alt=""/>
      <div class="number">{{ item.number }}</div>
    </div>
    <div class="name">{{ item.title }}</div>
    <div>{{ item.dTitle }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CustomWorkflow',
  props: {
    item: {
      default: {
        url: '',
        title: '',
        dTitle: '',
        number: 1,
      },
    },
  },
});
</script>

<style scoped>
.body {
  width: 126px;
  font-size: 14px;
  font-family:PingFangSC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
}

.number {
  font-size: 100px;
  font-family:PingFangSC-Regular, PingFang SC,sans-serif;
  font-weight: 400;
  color: #f3f3f3;
  line-height: 140px;
}

.img {
  width: 40px;
  height: 40px;
  margin-top: 69px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-bottom: 19px;
}
</style>

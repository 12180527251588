<template>
  <component :is="current"></component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProjectSignUpView from './ProjectSignUpView.vue';
import ProjectSignUpSeView from './ProjectSignUpSeView.vue';
import { aimdUrlArr } from '@/store/config';
export default defineComponent({
  name: 'HomeView',
  components: {
    ProjectSignUpView,
    ProjectSignUpSeView,
  },
  data() {
    return {
      current: 'ProjectSignUpView',
    };
  },
  created() {
    this.current = 'ProjectSignUpView';
    
    // if (window.screen.width <= 900) {
    //   this.$router.push('/h5/expert');
    // } else {
      // if (aimdUrlArr.indexOf(window.location.host) > -1) {
      //   this.current = 'ProjectSignUpView';
      // } else {
      //   this.current = 'ProjectSignUpSeView';
      // }
    // }
  },
});
</script>

<style scoped></style>

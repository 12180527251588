<template>
  <el-container class="page">
    <el-header>
      <Header :username="username" :url="url" />
    </el-header>
    <el-container>
      <el-aside width="300px" style="background-color: white">
        <Menu />
      </el-aside>
      <el-main>
        <div class="flex-row">
          <BreadCrumb />
          <div v-if="showFileManageMessage" class="message-notivation">
            请主管单位于2023年11月15日  24:00完成推荐表的上传
          </div>
        </div>

        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Menu from '@/components/nav/Menu.vue';
import BreadCrumb from '@/components/nav/BreadCrumb.vue';
import Header from '@/components/header/Header.vue';
import {
  getProfile,
  getAddressData,
  getProductType,
  getOrganizationType,
  getUserIcon,
} from '@/api/api';
import { mapMutations } from 'vuex';

export default defineComponent({
  name: 'MainView',
  components: { Menu, BreadCrumb, Header },
  created() {
    getProfile().then(({ data }: { data: any }) => {
      this.username = data.name;
      if (data.avatar) {
        getUserIcon(data.avatar).then((data: any) => {
          this.url = data;
        });
      }
    });
    getAddressData().then(({ data }: { data: any }) => {
      this.setAddressData(data);
    });
  },
  computed: {
    showFileManageMessage: function () {
      return this.$route.fullPath.includes('/proj-mgmt/filesManage/');
    },
  },
  data() {
    return { username: '', url: '' };
  },

  methods: {
    ...mapMutations(['setAddressData', 'setOrgTypeArr', 'setProdTypeArr']),
  },
});
</script>

<style scoped>
.page {
  min-width: 1200px;
  background: #f3f6fd;
}
.el-header {
  height: unset;
  padding: 0;
  font-size: 18px;
}
.el-aside {
  overflow: unset;
  overflow-y: clip;
}
.message-notivation {
  padding-top: 3px;
  margin-left: 20px;
  color: #f56c6c;
  font-size: 14px;
}
</style>

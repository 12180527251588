import { RouteRecordRaw } from 'vue-router';

const ProjDemandRoutes: RouteRecordRaw[] = [
  {
    path: '/proj-demand',
    component: () => import('@/components/proj-demand/DemandView.vue'),
    meta: { auth: ['ROLE_REPORT', 'ROLE_USER', 'ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'], group: 'proj-demand', menuGroup: 'proj-demand' },
    children: [
      { path: '', redirect: '/proj-demand/list/1' },
      {
        path: 'me', component: () => import('@/components/proj-demand/DemandDetail.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理' }, { label: '我的需求' }] }, auth: ['ROLE_USER']
      },
    
      {
        path: 'edit', component: () => import('@/components/proj-demand/DemandEdit.vue'),
        meta: { breadcrumb: [{ label: '我的需求', path: '/proj-demand/me' }, { label: '编辑需求' }] }, auth: ['ROLE_USER']
      },
      {
        path: 'apply/detail/:id',
        component: () => import('@/components/proj-demand/ProjDemandApplyDetail.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理' }, { label: '揭榜列表', path: '/proj-apply/me/list/1' }, { label: '揭榜详情' }] },
      },
      {
        path: 'list/:page?',
        component: () => import('@/components/proj-demand/ListView.vue'),
        meta: { breadcrumb: [{ label: '需求大厅' }], auth: ['ROLE_REPORT'] },
      },
      {
        path: 'item/detail/:id',
        component: () => import('@/components/proj-demand/DemandDetail.vue'),
        meta: { breadcrumb: [{ label: '我的揭榜申报', path: '/proj-apply/list/1' }, { label: '需求详情' }], auth: ['ROLE_REPORT'] },
      },
      {
        path: 'admin/list/:page?',
        component: () => import('@/components/proj-demand/ListView.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理' }] },
        auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER']
      },
      {
        path: 'admin/demand/detail/:id',
        component: () => import('@/components/proj-demand/DemandDetail.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理', path: '/proj-demand/admin/list/1' }, { label: '需求详情' }], auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'] },
      },
      {
        path: 'admin/list/:demandId/:page',
        component: () => import('@/components/proj-demand/DemandApplyList.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理', path: '/proj-demand/admin/list/1' }, { label: '揭榜列表' }], auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'] },
      },
    ],
  },
  {
    path: '/proj-apply',
    component: () => import('@/components/proj-demand/DemandView.vue'),
    meta: { auth: ['ROLE_REPORT', 'ROLE_USER', 'ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'], group: 'proj-apply', menuGroup: 'proj-apply' },
    children: [
      { path: '', redirect: '/proj-apply/list/1' },
      {
        path: 'me/list/:page?', component: () => import('@/components/proj-demand/DemandApplyList.vue'),
        meta: { breadcrumb: [{ label: '揭榜需求管理' }, { label: '揭榜列表' }] }, auth: ['ROLE_USER']
      },
      {
        path: 'list/:page?',
        component: () => import('@/components/proj-demand/ApplyList.vue'),
        meta: { breadcrumb: [{ label: '我的揭榜申报' }] },
      },
      {
        path: 'admin/list/:page?/:demandId?',
        component: () => import('@/components/proj-demand/DemandApplyList.vue'),
        meta: { breadcrumb: [{ label: '揭榜列表' }], auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'] },
      },
      {
        path: 'item/:id/:applyId?',
        component: () => import('@/components/proj-demand/ProjDemandApply.vue'),
        meta: { breadcrumb: [{ label: '需求大厅', path: '/proj-demand/list/1' }, { label: '揭榜申报' }] },
      },
      {
        path: 'item/detail/:id',
        component: () => import('@/components/proj-demand/ProjDemandApplyDetail.vue'),
        meta: { breadcrumb: [{ label: '需求大厅', path: '/proj-demand/list/1' }, { label: '揭榜申报' }] },
      },
      {
        path: 'admin/apply/detail/:id',
        component: () => import('@/components/proj-demand/ProjDemandApplyDetail.vue'),
        meta: { breadcrumb: [{ label: '揭榜列表', path: '/proj-apply/admin/list/1' }, { label: '揭榜申报' }] },
      },
    ],
  },
];

export default ProjDemandRoutes;

<template>
  <div>
    <div v-if="list.length > 0" class="breadcrumb flex-vcenter">
      <div class="lbl">{{ list[list.length - 1].label }}</div>
      <div v-if="list.length > 1" class="flex-vcenter">
        <div class="split-line"></div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="(item, index) in list" :to="item" :key="index">
            {{ item.label }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BreadCrumb',
  computed: {
    list(): { label: string; path?: string }[] {
      const { meta } = this.$route;
      if (meta !== undefined) {
        const breadcrumb = meta.breadcrumb as
          | { label: string; path?: string }[]
          | undefined;
        return breadcrumb !== undefined ? breadcrumb : [];
      } else {
        return [];
      }
    },
  },
});
</script>

<style scoped>
.breadcrumb {
  margin-bottom: 18px;
}
.lbl {
  font-size: 18px;
  font-weight: bold;
  color: #2c384d;
  line-height: 25px;
}
.split-line {
  width: 1px;
  height: 16px;
  background: #c6d1e0;
  margin: 0 24px;
}
</style>

import * as Sentry from "@sentry/vue";
import config from './package.json';
let version = config.version, commitId = config.commitId;
const ENV = location.href.includes("demo")
  ? "dev"
  : location.href.includes("localhost")
    ? "local"
    : "production";
console.log(`${ENV}_${version}_${commitId}`)
class SentryReport {
  // sentry = Sentry;
  // dsn = 'https://71ef3cb26b6b417d9df29753ba801876@sentry.3incloud.com/9';

  install (app, router) {
    this.init(app, router);
    // 这里把Sentry挂载为vue全局属性，在选项式可以通过this.$sentry访问，而在组合式setup是没有this，要通过const { proxy } = getCurrentInstance();proxy.$sentry访问
    app.config.globalProperties.$sentry = Sentry;
  }
 
  init (app, router) {
    // if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        app,
        // 区分环境，可通过.env文件中设置
        environment: ENV,
        // 1、登录https://sentry.3incloud.com/organizations/caict3in/projects/
        // 2、查看路径： Settings > caict3in > 所选项目 > Client Keys（图见1.3）
        dsn: this.dsn,
        integrations: [
          // 追踪记录路由路径
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          // 回放采样，更多配置项参考：https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/
          new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        // 默认值为['localhost', /^\//]，默认情况下，添加的sentry-trace和baggage请求头仅附加到URL包含localhost的请求或同浏览器页面访问域名下的请求，例如GET /api/v1/users，且后端需要添加"Access-Control-Allow-Headers: sentry-trace"和"Access-Control-Allow-Headers: baggage"的配置，目前没看到效果
        tracePropagationTargets: ["localhost", /^https:\/\/sh-metaverse\.demo\.3incloud\.cn/, /^https:\/\/metaverse\.3incloud\.com/],
        // 跟踪涵盖0-1
        tracesSampleRate: 1.0,
        // 回放错误会话采样比率
        replaysOnErrorSampleRate: 1.0,
        // 回放会话采样比率，生产设置不需要过高
        replaysSessionSampleRate: 0.1,
        // 绑定版本号 ·`${环境-通过在.env文件中设置}_${版本号-读取package.json里面的version版本号}_${git的最近一次提交的commitId-读取package.json里面的commitId}`，便于后续平台筛选记录
        release: `${ENV}_${version}_${commitId}`,
        // sentry 提供发送错误前的钩子 beforeSend
        beforeSend (event, hint) {
            // 可以在此修改 event 中属性
            // event.xxx = xxxx
            return event
            // 返回 null， 表示过滤掉事件，不上传
            // return null
         }
      });
      
    // }
  }
}
 
export default SentryReport;
<template>
  <div class="body flex-row">
    <div style="width: 38px">
      <div class="tick-view flex-center">
        <img style="width: 14px; height: 11px" src="@/assets/home/tick.svg" alt=""/>
      </div>
    </div>
    <div>
      <div class="tick-title">{{ item.title }}</div>
      <div class="tick-desc">
        {{ item.dTitle }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CustomRequire',
  props: {
    item: {
      default: {
        title: '',
        dTitle: '',
      },
    },
  },
});
</script>

<style scoped>
.body {
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1),
    0px 0px 15px 0px rgba(0, 119, 255, 0.6);
  border-radius: 8px;
  padding: 20px;
  width: 516px;
}

.tick-view {
  width: 24px;
  height: 24px;
  background: linear-gradient(315deg, #00a7fe 0%, #0077ff 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 119, 255, 0.4);
  border-radius: 50%;
  margin-right: 14px;
}

.tick-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}

.tick-desc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-top: 10px;
}
</style>

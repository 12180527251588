import { RouteRecordRaw } from 'vue-router';

const ReviewMgmtRoutes: RouteRecordRaw[] = [
  {
    path: '/admin/review-mgmt',
    component: () => import('@/components/admin/review-mgmt/ReviewView.vue'),
    meta: {
      auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
      group: 'admin-project',
      breadcrumb: [{ label: '评审管理' }],
    },
  },
  {
    path: '/admin/review-mgmt/new-task',
    component: () => import('@/components/admin/review-mgmt/NewTaskView.vue'),
    meta: {
      auth: ['ROLE_PLATFORM_ADMIN', 'ROLE_PLATFORM_USER'],
      group: 'admin-project',
      breadcrumb: [
        { label: '评审管理', path: '/admin/review-mgmt' },
        { label: '创建总任务' },
      ],
    },
  },
];

export default ReviewMgmtRoutes;

<template>
  <div class="body">
    <div class="float_l"></div>
    <div class="float_r"></div>
    <UnauthHeader />
    <!-- banner部分 -->
    <div class="topBanner">
      <h1 class="bannerTitle">{{ aimdName.slice(0, -8) }}</h1>
      <div class="toSignBtn" @click="jump">揭榜入口</div>
    </div>
    <!-- 项目说明 -->
    <div class="projectInfo">
      <div class="projectCenter">
        <div class="proLeft">
          <div class="proName">项目背景</div>
          <img class="title" src="/images/first-page/title.png" alt="" style="margin: 0;">
          <div class="proMes">
            为贯彻落实工业和信息化部、教育部、文化和旅游部、国务院国资委、国家广播电视总局等五部门联合印发的《元宇宙产业创新发展三年行动计划（2023—2025年）》以及《上海市培育“元宇宙”新赛道行动方案》，加快本市元宇宙新赛道布局，培育发展新动能，推动更多应用场景落地，牵引带动元宇宙相关产业高质量发展，市经济信息化委、市文化旅游局、市卫生健康委、市教委拟联合开展2023年本市元宇宙重大应用场景需求“揭榜挂帅”工作。
          </div>
          <div class="infoLink" style="margin-bottom: 10px;" @click="openNewPage('https://www.sheitc.sh.gov.cn/cyfz/20231020/fb8f0655cd8c4aefac962ad5d08386f6.html')">
            <img src="/images/first-page/file.svg" alt="">
            关于组织开展2023本市元宇宙重大应用场景需求“揭榜挂帅”的通知
          </div>
          <div class="infoLink" @click="openNewPage('https://www.sheitc.sh.gov.cn/gg/20240206/a4c9aa0eec724f6c8df86a5e2deac8f5.html')">
              <img src="/images/first-page/file.svg" alt="">
              关于上海市第二批元宇宙重大应用场景 “揭榜挂帅”项目发榜的通知
            </div>
          <!-- <div class="viewInfo" @click="downloadFile">下载文档</div> -->
        </div>
        <!-- <img class="proRight" src="/images/first-page/proRight.webp" alt="" /> -->

      </div>
    </div>
    <!-- 说明 -->
    <div class="questionInform">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;">赛道方向</div>
        <img class="title" src="/images/first-page/title.png" alt="">
        <div class="direction_desc">重点面向结合人工智能、扩展现实、数字孪生、新一代通信、区块链等创新技术，支持以下四大领域方向场景应用的本市元宇宙领域在建重大应用场景。</div>
        <div class="questionUl">
          <div class="questionLi" @transitionend="item.expandText = item.active ? '收起' : '展开更多'" v-for="(item, ii) in directionInfoList" :class="[item.active ? 'active' : '']">
            <img :src="getImgUrl(`/images/first-page/card_${ii + 1}_bg.webp`)" alt="bg" class="bgImg" />
            <div class="questionIco">
              <img :src="getImgUrl(item.icon)" alt="" />
            </div>
            <div class="questionMes">
              <div>
                <div class="qmName">{{ item.title }}</div>
                <div class="qmTxt" v-for="(introduce, index) in item.items">
                  <img class="introduce_num" :src="getImgUrl(`/images/first-page/num_${index + 1}.webp`)" alt="" />
                  <div class="introduce_content">
                    <div class="introduce_title">{{ introduce.title }}</div>
                    <div>{{ introduce.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomPart" @click="toggleExpand(ii)">
              {{ item.expandText }}<span class="expand"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 申请范围 -->
    <div class="applyRange">
      <div class="projectCenter">
        <div class="proName" style="text-align: center;color: #FFFFFF;">申报条件</div>
        <img class="title" style="margin: 0 auto;" src="/images/first-page/titles.png" alt="" />
        <div class="applyRangeUl">
          <div class="applyRangeLi">
            申报主体为上海市内注册、具备独立法人资格的重点企业、高等院校、科研院所、产业园区等机构单位。申报主体应具备支撑应用场景建设和运营的必要基础，在质量、安全、信誉和社会责任等方面无不良记录。
          </div>
          <div class="applyRangeLi">
            允许以联合体方式参与申报，牵头单位为1家，联合单位不超过3家。
          </div>
          <div class="applyRangeLi">
            各单位可申报多个场景，每个场景仅可选择一个赛道方向，若需要申报多个场景，需分多次单独完成申报。
          </div>
        </div>
      </div>
    </div>
    <div class="relatedInfoCon">
      <!-- 组织与安排 -->
      <div class="relatedInfo">
        <div class="proName" style="text-align: center;">相关信息</div>
        <img class="title" style="margin: 0 auto;" src="/images/first-page/title.png" alt="" />
        <!-- 工作流程 -->
        <div class="workFlow">
          <h3>工作流程</h3>
          <div class="workFlowItems">
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem1.webp" alt="" /><span>1</span>
              <div>
                <h4>场景申报</h4>
                单位在线填写申请和提交申报材料
              </div>
            </div>
            <img src="/images/first-page/arrow.png" alt="" />
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem2.webp" alt="" /><span>2</span>
              <div>
                <h4>场景遴选</h4>
                组织专家评审会，遴选优秀元宇宙建设场景
              </div>
            </div>
            <img src="/images/first-page/arrow.png" alt="" />
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem2-2.webp" alt="" /><span>3</span>
              <div>
                <h4>发榜揭榜</h4>
                公开发布入榜应用场景建设需求，各企业按需揭榜
              </div>
            </div>
            <img src="/images/first-page/arrow.png" alt="" />
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem3.webp" alt="" /><span>4</span>
              <div>
                <h4>供需对接</h4>
                入围场景单位按照要求开展供需对接活动
              </div>
            </div>
            <img src="/images/first-page/arrow.png" alt="" />
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem4.webp" alt="" /><span>5</span>
              <div>
                <h4>场景建设</h4>
                入围场景单位联合揭榜单位按实施方案开展场景建设
              </div>
            </div>
            <img src="/images/first-page/arrow.png" alt="" />
            <div class="workFlowItem">
              <img src="/images/first-page/workFlowItem5.webp" alt="" /><span>6</span>
              <div>
                <h4>成果发布</h4>
                组织开展评价工作并发布重大应用场景名单
              </div>
            </div>
          </div>
        </div>
        <!-- 申报日期 -->
        <div class="applyDate">
          <h3>申报日期</h3>
          <div>
            <img src="/images/first-page/canlender.png" alt="">
            <div>
              <h4>2023年10月20日-11月15日</h4>
              逾期不予受理
            </div>
          </div>
        </div>
        <!-- 组织架构 -->
        <div class="organWidth">
          <div class="projectCenter">
            <div class="organStruct">
              <h3>组织架构</h3>
              <div style="justify-content: normal;gap: 40px">
                <div style="width: 270px;">
                  <h4>组织单位</h4>
                  <div>上海市经济和信息化委员会</div>
                  <div>上海市文化和旅游局</div>
                  <div>上海市卫生健康委员会</div>
                  <div>上海市教育委员会</div>
                </div>
                <div style="width: 270px;">
                  <h4>支撑单位</h4>
                  <div>中国信通院上海工创中心</div>
                  <div>上海智慧城市发展研究院</div>
                </div>
                <div style="width: 270px;">
                  <h4>技术支持</h4>
                  <div>中国信通院上海工创中心</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="consult_con">
      <div class="proName" style="text-align: center;">申报咨询</div>
      <img class="title" style="margin: 0 auto;" src="/images/first-page/title.png" alt="" />
      <div class="organStructCon">
        <div class="organStruct">
        <h3 style="margin-top: 0px;">申报工作联系人</h3>
        <div>
          <div>
            <h4>工业、医疗赛道</h4>
            <div class="companytIco">中国信通院上海工创中心</div>
            <div class="nameIco">尤  伟 </div>
            <div class="telIco">021-68866367</div>
          </div>
          <div>
            <h4>文旅、教育赛道</h4>
            <div class="companytIco">上海智慧城市发展研究院</div>
            <div class="nameIco">李小锐</div>
            <div class="telIco">15317507760</div>
          </div>
        </div>
      </div>
      <div class="organStruct">
        <h3>赛道咨询联系人</h3>
        <div>
          <div>
            <h4>工业赛道</h4>
            <div class="companytIco">上海市经济信息化委软件和信息服务业处</div>
            <div class="nameIco">王新刚</div>
            <div class="telIco">021-60801194</div>
          </div>
          <div>
            <h4>文旅赛道</h4>
            <div class="companytIco">上海市文化旅游局信息科技处</div>
            <div class="nameIco">何潇</div>
            <div class="telIco">021-23118309</div>
          </div>
          <div>
            <h4>医疗赛道</h4>
            <div class="companytIco">上海市卫生健康委信息化管理处</div>
            <div class="nameIco">唐怡雯</div>
            <div class="telIco">021-23117727</div>
          </div>
          <div>
            <h4>教育赛道</h4>
            <div class="companytIco">上海市教委信息化工作处</div>
            <div class="nameIco">刘晨</div>
            <div class="telIco">021-23116859</div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="footerModule">Copyright © 2016-2023 上海工创中心 版权所有 | <a href="https://beian.miit.gov.cn/"
        style="color:rgba(255,255,255,0.6);text-decoration: none;">沪ICP备17037709号-3</a></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import UnauthHeader from '@/components/header/UnauthHeader.vue';
import { downloadFileSimple, getToken, getUserRole } from '@/utils';
import { aimdName, download_files } from '@/store/config';
export default defineComponent({
  name: 'ProjectSignUp',
  components: {
    UnauthHeader,
  },
  data() {
    return {
      aimdName,
      role: '',
      token: '',
      isEnd: false,
      download_url: download_files.sign_up_file,
      directionInfoList: [
        {
          icon: '/images/first-page/ai_make.webp',
          title: '工业领域',
          active: false,
          expandText: '展开更多',
          items: [
            {
              title: '元宇宙+产线',
              desc: '构建虚实结合的产线数字孪生体。打造工业元宇宙虚拟装配空间，推动零配件辅助装配。丰富非接触式检测手段，利用虚拟仿真设备实现三维自动化、智能化质检。建设工艺仿真模型库，引导规范产线操作，提供专家远程协同指导；'
            },
            {
              title: '元宇宙+工厂',
              desc: '建设工厂级元宇宙平台，打造低时延、高保真、智能决策的数字孪生系统。推动多类型工业软件集成，推进物流、资金流和信息流的融合应用。采用沉浸交互设备实现工厂智能巡检、远程协作等应用，打造生产运行监测系统，实现生产运营各环节信息全面感知和实时反馈；'
            },
            {
              title: '元宇宙+工业园区',
              desc: '发展虚实结合的新型园区建设模式，提升工业园区产业规划和布局能力。探索工业园区虚拟运营模式，优化园区空间布局、设施配套、资源调配等协同服务能力。建立基于工业元宇宙的科技创新平台，创新园区服务模式。'
            }
          ]
        },
        {
          icon: '/images/first-page/play.webp',
          bg: '/images/first-page/card_1_bg.webp',
          active: false,
          expandText: '展开更多',
          title: '文旅领域',
          items: [
            {
              title: '元宇宙+数字文化',
              desc: '支持博物馆、美术馆等文博场馆和商业街区使用XR、裸眼3D、全息投影、数字光影等虚拟现实技术和球（环）幕、智能感知等装备设备，打造虚实交互的沉浸式文旅体验空间；支持珍贵文物高精度三维数字化采集与展现；'
            },
            {
              title: '元宇宙+智慧旅游',
              desc: '支持面向“一江一河”、中心城区历史风貌区和历史街区打造立体化、可互动的虚实融合游览线路；支持景区景点通过沉浸式互动场景搭建，打造文旅融合的高体验度特色旅游项目；'
            },
            {
              title: '元宇宙+在线演艺',
              desc: '支持虚拟演艺制播平台搭建和内容产品创作，创新表演型数字人应用，鼓励打造虚拟偶像IP；支持剧场对舞美舞台装备进行升级改造，研发兼具文化性、艺术性、创新性的演艺产品；'
            },
            {
              title: '元宇宙+数字艺术品',
              desc: '支持数字艺术品跨场景应用；'
            },
            {
              title: '元宇宙+内容创作',
              desc: '支持基于新型终端开展精品创作，推出沉浸式、可交互的新一代数字内容产品；支持建设沉浸内容开源社区和数字文化资源共建共享平台。'
            }
          ]
        },
        {
          icon: '/images/first-page/wlcyIco.png',
          bg: '/images/first-page/card_1_bg.webp',
          active: false,
          expandText: '展开更多',
          title: '医疗领域',
          items: [
            {
              title: '元宇宙+医疗数字人',
              desc: '支持外科手术导航和定位、辅助检测、智能分诊、远程监控指导、严重脑部疾病诊疗等；'
            },
            {
              title: '元宇宙+远程医疗',
              desc: '支持远程会诊、远程手术、远程护理等；'
            },
            {
              title: '元宇宙+临床诊疗',
              desc: '支持疾病筛查、辅助诊断、辅助检测、辅助分诊、多学科会诊以及药物治疗、手术规划、术中辅助、心理治疗等；'
            },
            {
              title: '元宇宙+健康管理',
              desc: '支持慢性疾病、老年疾病、职业病、心理障碍、精神疾病等；'
            },
            {
              title: '元宇宙+医学教育',
              desc: '支持外科手术模拟、器械操作训练、解剖学教学、临床教学实践等；'
            },
            {
              title: '元宇宙+智慧医院',
              desc: '支持患者虚拟查房、导诊、取药、检查、检验、配药等；'
            },
            {
              title: '元宇宙+公共卫生',
              desc: '支持传染病流调信息管理、防疫信息任务系统与可视化一体化管理以及传染病监测预警等；'
            },
            {
              title: '元宇宙+其他方向',
              desc: '包括数字中医、数字医学、数字疗法、精准医疗等医疗场景。'
            }
          ]
        },
        {
          icon: '/images/first-page/wlcyjyIco.png',
          bg: '/images/first-page/card_1_bg.webp',
          active: false,
          expandText: '展开更多',
          title: '教育领域',
          items: [
            {
              title: '元宇宙+直播教学',
              desc: '利用元宇宙技术实现学生和教师之间的远程直播互动教学，通过虚拟平台进行情景化在线学习，提供更广泛的学习机会；'
            },
            {
              title: '元宇宙+虚拟智慧课堂',
              desc: '利用元宇宙技术打造虚拟智慧课堂，通过沉浸式的虚拟教室环境促进教师和学生真实互动，支持对虚拟智慧课堂进行智能控制和应用；'
            },
            {
              title: '元宇宙+个性化自适应学习',
              desc: '在元宇宙课堂教学环境中根据学生的学习风格、兴趣和能力提供个性化的学习资源和教学指导，支持个性化作业和问答咨询，满足自定步调的个性化学习需求；'
            },
            {
              title: '元宇宙+虚拟仿真实训',
              desc: '利用元宇宙技术开发虚拟仿真实训平台，为教师和学生提供高沉浸且安全的实践环境，支持各学科实训场景以及教师教学能力提升培训；'
            },
            {
              title: '元宇宙+学情评价',
              desc: '探索借助大数据、区块链等技术记录分析学习过程中的各项指标，以此辅助评价学生的学习效果，并为教师的学习监督、问题发现、行为干预等提供依据，并提供可视化的学习成果展示。'
            }
          ]
        }
      ]
    };
  },
  methods: {
    toggleExpand(ii: number) {
      const activeBol = this.directionInfoList[ii].active;
      this.directionInfoList.forEach((v, i) => {
        if (ii !== i) {
          v.active = false
        }
      })
      this.directionInfoList[ii].active = !activeBol;
    },
    getImgUrl(url: string) {
      return url;
    },
    openNewPage(link: string) {
      window.open(link, '_blank')
    },
    downloadFile() {
      // 关于组织开展2023年“上海市元宇宙第二批重大应用场景需求征集”应用试点项目申报工作的通知.docx notice.docx
      downloadFileSimple('/notice.docx', '关于征集本市第二批元宇宙重大应用场景需求“揭榜挂帅”的通知.docx')
      // window.open('https://inos.3incloud.com/public/xcjk/%E5%85%B3%E4%BA%8E%E7%BB%84%E7%BB%87%E5%BC%80%E5%B1%952023%E5%B9%B4%E2%80%9C%E5%AE%BD%E5%B8%A6%E7%BD%91%E7%BB%9C+%E5%81%A5%E5%BA%B7%E4%B9%A1%E6%9D%91%E2%80%9D%E5%BA%94%E7%94%A8%E8%AF%95%E7%82%B9%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E5%B7%A5%E4%BD%9C%E7%9A%84%E9%80%9A%E7%9F%A5.docx');
    },
    jump() {
      this.$router.push('/demand')
      // if (this.token) {
      //   if (this.role === 'ROLE_USER') {
      //     this.$router.push('/proj-apls/center/1');
      //   } else if (
      //     this.role === 'ROLE_PLATFORM_ADMIN' ||
      //     this.role === 'ROLE_PLATFORM_USER'
      //   ) {
      //     //平台管理员或超级管理员
      //     this.$router.push('/admin/user/registered/list/1');
      //   } else if (
      //     this.role === 'ROLE_INSTITUTION_ADMIN_LOWER' ||
      //     this.role === 'ROLE_INSTITUTION_ADMIN' ||
      //     this.role === 'ROLE_INSTITUTION_USER'
      //   ) {
      //     //初审单位用户或管理员
      //     this.$router.push('/proj-mgmt/list/1');
      //   } else if (this.role === 'ROLE_EXPERT') {
      //     this.$router.push('/proj-review');
      //   }
      // } else {
      //   this.$router.push(`/auth/login`);
      // }
    },
  },
  created() {
    this.token = getToken();
    this.role = getUserRole();
    if (1639583999000 > new Date().getTime()) {
      this.isEnd = false;
    } else {
      this.isEnd = true;
    }
  },
});
</script>
<style scoped>
.telIco::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("/images/first-page/telIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}

.telIco {
  padding: 0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.nameIco::before, .companytIco::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  background-image: url("/images/first-page/nameIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 2px;
}
.companytIco::before {
  background-image: url("/images/first-page/companytIco.webp");
}
.nameIco, .companytIco {
  padding: 0 0 0 26px;
  height: auto;
  overflow: hidden;
  position: relative;
}

.organWidth {
  width: 100%;
  min-height: 100px;
  height: auto;
  overflow: hidden;
}

.applyRangeLi::before {
  content: "";
  display: block;
  width: 18px;
  height: 14px;
  background-image: url("/images/first-page/applyIco.png");
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 5px;
}

.applyRangeLi {
  padding: 0 0 20px 30px;
  height: auto;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  color: #ffffff;

}

.applyRangeUl {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 60px;
}

.qmTxt {
  display: flex;
  margin-top: 20px;
}

.introduce_content {
  width: 460px;
}

.introduce_num {
  width: 30px;
  height: 28px;
  margin-right: 12px;
}

.introduce_title {
  font-weight: bold;
  color: #333333;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 5px;
}

.qmName {
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  font-weight: bold;
  margin-bottom: 30px;
}

.questionIco {
  width: 64px;
  height: 64px;
  background: #0077FF;
  border-radius: 32px;
  padding: 16px !important;
  margin-bottom: 20px;
  margin-left: 40px;
}

.questionIco img {
  display: block;
  width: 32px;
  height: 32px;
}

.questionMes {
  /* padding: 0 0 0 84px; */
  max-height: 375px;
  /* padding-bottom: 36px !important; */
  box-sizing: content-box;
  /* height: 375px; */
  overflow: hidden;
  transition: all .5s linear;
  background: linear-gradient(180deg, transparent 375px, #fff 375px);
}

.questionLi {
  width: 576px;
  max-height: 573px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  border-radius: 24px;
  padding: 30px 0px 0px;
  position: relative;
  margin-bottom: 40px;
  color: #666666;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  position: relative;
  flex: none;
  overflow: visible;
  transition: all .5s linear;
}
.questionLi > * {
  padding: 0 40px;
}
.questionLi:hover, .questionLi.active {
  background-color: #fff;
}
.questionLi:nth-child(-n+2){
  /* z-index: 1; */
}
.questionLi:nth-child(-n+2).active {
  max-height: 1000px;
}
.questionLi.active .questionMes {
  max-height: 1000px;
  /* height: auto; */
  background: linear-gradient(180deg, transparent 375px, #fff 375px);
  
}
.bottomPart {
  padding: 36px 0 30px;
  text-align: center;
  color: #999999;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  border-radius: 0px 0px 24px 24px;
  background: #fff;
}
.bottomPart:hover {
  color: #0077FF;
}

.bottomPart .expand {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  vertical-align: middle;
  background: url('/images/first-page/arrow_down.webp') 0 0 / cover;
}
.bottomPart:hover .expand {
  background: url('/images/first-page/arrow_down_active.webp') 0 0 / cover;
}
.questionLi.active .bottomPart .expand {
  background: url('/images/first-page/arrow_up.webp') 0 0 / cover;
}
.questionLi.active .bottomPart:hover .expand {
  background: url('/images/first-page/arrow_up_active.webp') 0 0 / cover;
}

.bgImg {
  position: absolute;
  right: 0;
  top: 273px;
  width: 300px;
  height: 300px;
  z-index: 2;
  pointer-events: none;
  padding: 0 !important;
}

.questionLi:nth-child(2n) {
  margin-left: 48px;
}

.questionUl {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.proMes {
  padding: 40px 0 30px 0;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
}

.title {
  display: block;
  width: 40px;
  height: 6px;
}

.proName {
  width: 100%;
  height: auto;
  line-height: 45px;
  font-size: 32px;
  color: #000000;
  font-weight: 600;
  padding: 60px 0 18px 0;
}

.direction_desc {
  color: #333333;
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 60px;
}

.proLeft {
  width: 540px;
  height: 595px;
  /* float: left; */
}

.projectCenter {
  width: 1200px;
  height: auto;
  /* overflow: hidden; */
  margin: 0 auto;
  position: relative;
}

.body {
  min-width: 1300px;
  font-size: 14px;
  color: #333333;
  line-height: 24px;
  user-select: none;
  position: relative;
  /* background: url('/images/first-page/float_l.webp') left 961px / 230px 264px no-repeat, url('/images/first-page/float_r.webp') right 1821px / 190px 190px no-repeat; */
}
.float_l {
  position: absolute;
  left: 0px;
  top: 961px;
  width: 230px;
  height: 264px;
  background: url('/images/first-page/float_l.webp') 0 0 / cover;
  pointer-events: none;
}
.float_r {
  position: absolute;
  right: 0px;
  top: 1821px;
  width: 190px;
  height: 190px;
  background: url('/images/first-page/float_r.webp') 0 0 / cover;
  pointer-events: none;
}
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: #000000;
  font-weight: normal;
}

.topBanner {
  height: 460px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  background: url('/images/first-page/banner.webp') center 0 / cover;
  padding-top: 161px;
}

h1 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 41px;
  color: #fff;
}

.toSignBtn {
  margin: auto;
  width: 160px;
  line-height: 50px;
  border-radius: 25px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.toSignBtn img {
  margin-left: 10px;
}

.go2 {
  display: none;
}

.toSignBtn:hover {
  color: #0077FF;
  background: #FFFFFF;
}

.toSignBtn:hover .go1 {
  display: none;
}

.toSignBtn:hover .go2 {
  display: inline-block;
}
/* .proRight {
  position: absolute;
  left: 570px;
  top: 0;
  width: 990px;
  height: 595px;
  display: block;
} */
.projectInfo {
  /* height: 499px; */
  margin: 0 auto;
  text-align: justify;
  background: url('/images/first-page/proRight.webp') calc(50% + 465px) 0 / 990px 595px  no-repeat;
}

.projectInfo h2 {
  margin-top: 0;
}

.infoContent+.infoContent {
  margin-top: 12px;
}

h2 {
  color: #000000;
  line-height: 45px;
  margin: 0px 0px 18px;
  font-size: 32px;
  text-align: center;
}

h2+img {
  width: 40px;
  display: block;
  margin: 0px auto 40px;
}

.infoLink {
  color: #2164B1;
  line-height: 20px;
  margin: 0px auto 40px auto;
  white-space: nowrap;
}

.infoLink img {
  width: 14px;
  margin-right: 8px;
  vertical-align: middle;
}

.viewInfo:hover {
  border: 1px solid #288CFF;
  color: #288CFF;
}

.viewInfo {
  width: 128px;
  line-height: 40px;
  border-radius: 20px;
  border: 1px solid #0077FF;
  color: #0077FF;
  text-align: center;
  cursor: pointer;
}

.projectCenter .proName+img {
  margin: 0 auto 40px;
}

.questionInform img.leftImg {
  width: 480px;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0px;
}

.questionInform {
  width: 100%;
  padding: 0px 0 60px;
  /* height: 1537px; */
  /* overflow: visible; */
  /* questionInform_bg */
  background: url('/images/first-page/questionInform_bg.webp') 100% 100%;
}

.infoListCon {
  display: flex;
  justify-content: space-between;
}

.questionInform .infoList {
  width: 676px;
}

.infoList h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  margin: auto;
}

.infoList h3:after {
  content: '';
  width: 0;
  overflow: hidden;
}

.infoList div div {
  margin: 10px 0 30px;
  display: inline-block;
}

.infoList div:last-of-type div {
  margin-bottom: 0px;
}

.questionInform h2,
.applyCondition h2 {
  text-align: center;
}

.applyCondition img {
  margin: 0 0 40px 0;
}

.targetContent {
  width: 600px;
  float: right;
}

.applyRange {
  width: 100%;
  height: 444px;
  background: url("/images/first-page/applyRange.webp") center no-repeat;
  background-size: cover;
}

.rangeContent {
  margin-top: 60px;
  /* display: flex; */
  /* justify-content: space-between; */
  color: #666666;
  margin-right: -39px;
}

.rangeContent>div {
  width: 374px;
  min-height: 487px;
  background: #fff;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.06);
  float: left;
  margin-right: 39px;
  overflow: hidden;
}

.rangeContent>div:hover {
  box-shadow: 0px 0px 40px 0px rgba(0, 119, 255, 0.15);
  border: 1px solid #0077FF;
}

.rangeContent>div:hover .rangeTitle+div {
  -webkit-line-clamp: 30;
}

.rangeTitle+div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.rangeContent img {
  width: 374px;
  height: 280px;
}

.rangeContent img+div {
  padding: 24px;
}

.rangeTitle {
  color: #333333;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}

h3 {
  margin: 24px auto 14px;
  text-align: left;
  color: #333333;
  line-height: 25px;
  font-weight: bold;
  font-size: 22px;
}

.applyCondition {
  padding: 60px calc(50% - 600px) 0 calc(50% + 80px);
  /* background: url('/images/first-page/applyConditionL.png') 0 0 / 50% 100% no-repeat,
    url('/images/first-page/applyConditionR.png') 50% 0 / 100% 100% no-repeat; */
  height: 589px;
}

.applyCondition h2 {
  color: #fff;
  text-align: left;
}

.conditionText {
  margin-top: 58px;
  color: #FFFFFF;
  line-height: 26px;
  font-size: 16px;
  position: relative;
  width: 530px;
}

.conditionText::before {
  content: attr(data_index);
  width: 68px;
  line-height: 68px;
  background: #FFFFFF;
  color: #0077FF;
  font-size: 32px;
  border-radius: 50%;
  top: 50%;
  margin-top: -34px;
  position: absolute;
  text-align: center;
  left: -115px;
}

.conditionText:first-of-type {
  margin-bottom: 65px;
}

.conditionText:last-of-type {
  margin-top: 78px;
}
.relatedInfoCon {
  background: url('/images/first-page/relatedInfoCon_bg.webp') 0 0 / 100% 100%;

}
.relatedInfo {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  color: #666666;
  line-height: 22px;
  padding-bottom: 60px;
}

h3 {
  margin: 60px auto 30px;
  text-align: left;
  position: relative;
}

h3::after {
  content: ' ';
  position: absolute;
  width: 64px;
  height: 12px;
  background: url('/images/first-page/h3.png') 0 0 / cover;
  top: 50%;
  margin-top: -6px;
  margin-left: 14px;
}

.workFlowItems {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.workFlowItem > div {
  width: 126px;
}
.workFlowItem {
  width: 126px;
  flex: none;
  overflow: visible;
}
.workFlowItem img {
  width: 40px;
}

.workFlowItem span {
  font-size: 100px;
  color: #F3F3F3;
  line-height: 140px;
}

.workFlowItems>img {
  width: 63px;
  height: 10px;
  margin: 84px 40px 152px -28px;
}

h4 {
  margin-bottom: 19px;
  color: #333333;
  line-height: 22px;
  font-size: 16px;
  font-weight: bold;
}

.applyDate>div {
  display: flex;
  align-items: flex-start;
}

.applyDate img {
  width: 32px;
  margin-right: 19px;
}

.applyDate h4 {
  margin-bottom: 8px;
}
.consult_con {
  background: url('/images/first-page/consult_con.webp') 0 0 / 100% 100%;
  padding-bottom: 100px;
}
.consult_con .organStructCon {
  width: 1200px;
  padding: 40px 20px 60px 60px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  /* opacity: 0.8; */
  margin: 100px auto 0px;
}
.consult_con .organStruct {
  margin-bottom: -10px;
}

.consult_con .organStruct h3 {
  font-weight: bold;
  color: #333333;
  line-height: 33px;
  font-size: 24px;
  margin-bottom: 30px;
}
.consult_con .organStruct h3::after {
  width: 168px;
  height: 8px;
  background: rgba(0,119,255,0.12);
  bottom: 0;
  left: 0px;
  left: 0px;
  top: auto;
  margin: 0;
}
.consult_con .organStruct h4~div {
  margin-bottom: 10px;
}
.organStruct {
  margin-bottom: 14px;
}

.organStruct>div {
  display: flex;
  /* justify-content: space-between; */
  color: #666666;
  line-height: 22px;
  gap: 53px;
}
.consult_con .organStruct>div>div{
  width: 240px;
}

.organStruct h4 {
  margin-bottom: 18px;
}

.organStruct h4~div {
  margin-bottom: 6px;
}

.support {
  color: #333333;
  font-size: 16px;
  line-height: 22px;
}

.support h4 {
  margin-bottom: 30px;
}

.support>div {
  margin-bottom: 16px;
}

.applyConsult>div {
  display: flex;
}

.business,
.technique {
  width: 300px;
  height: 96px;
}

.widthMax {
  width: 380px;
}

.businessTel {
  display: flex;
  justify-content: space-between;
}

.applyConsult h4 {
  margin-bottom: 22px;
  min-height: 44px;
}

.business {
  margin-right: 96px;
}

.businessTel {
  color: #666666;
  line-height: 22px;
}

.applyConsult img {
  width: 18px;
  vertical-align: -3px;
  margin-right: 8px;
}

.mb14 {
  margin-bottom: 14px;
}

.footerModule {
  width: 100%;
  height: 80px;
  background: #33334F;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  line-height: 80px;
}
</style>

